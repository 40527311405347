<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Лендинги</h2>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <v-text-field
          outlined
          dense
          hide-details
          clearable
          label="Название"
          v-model="params.package_name"
          @click:clear="onClear"
          @input="filterApps"
        />
      </div>
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="profiles"
          item-value="id"
          item-text="name"
          label="Пользователь"
          v-model="params.profile_id"
          @input="filterApps"
        />
      </div>
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          :items="statuses"
          item-value="value"
          item-text="name"
          label="Статус"
          v-model="params.status"
          @input="filterApps"
        />
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="create"
        >Добавить</b-button>
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="applications.results"
      paginated
      backend-pagination
      :total="applications.total"
      :per-page="params.perPage"
      @page-change="onPageChange"
      class="users-table"
    >
      <b-table-column
        label="Название"
        v-slot="props"
      >
        <span>{{ props.row.package_name}}-{{props.row.keitaro_id}}({{props.row.landing_name}})</span>
      </b-table-column>

      <b-table-column
        field="phone"
        label="Статус"
        v-slot="props"
      >
        <span>{{ statuses.find(o => o.value === props.row.status).name }}</span>
      </b-table-column>

      <b-table-column
        field="second_name"
        label="Действия"
        v-slot="props"
      >
        <div>
          <b-tooltip label="Редактировать">
            <b-button class="edit_icon" @click="edit(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Удалить">
            <b-button
              class="delete_icon"
              @click="remove(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </b-button>
          </b-tooltip>
        </div>
      </b-table-column>

      <!-- Пагинация и выбор количества элементов на странице -->
      <template slot="bottom-left">
        <div class="pagination-controls">
          <v-select
            style="width: fit-content; float: left;"
            v-model="params.perPage"
            @change="filterApps"
            :items="perPageOptions"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            dense
          />
        </div>
      </template>

    </b-table>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "AdminLandingsList",
  components: {
    draggable,
  },
  data() {
    return {
      params: {
        package_name: '',
        profile_id: null,
        status: 'active',
        perPage: 20, // передаем только число
        page: 1
      },
      statuses: [
        { value: 'active', name: 'Активно' },
        { value: 'block', name: 'Заблокировано' },
        { value: 'moder', name: 'На модерации' },
        { value: 'develop', name: 'В разработке' },
        { value: 'deleted', name: 'Удалено' },
        { value: '', name: 'Все' },
      ],
      perPageOptions: [
        { name: "10 на странице", value: 10 },
        { name: "20 на странице", value: 20 },
        { name: "50 на странице", value: 50 },
        { name: "100 на странице", value: 100 },
      ],
    };
  },
  created() {
    this.filterApps();
    this.$store.dispatch("GET_PROFILES", { type: 'SET_PROFILES' });
  },
  computed: {
    applications() {
      return this.$store.getters.APPLICATIONS;
    },
    profiles() {
      return this.$store.getters.GET_PROFILES;
    },
  },
  methods: {
    onPageChange(page) {
      this.params.page = page;
      this.filterApps();
    },
    edit(application) {
      this.$router.push({ path: `/application/update/${application.id}` }).catch(() => {});
    },
    create() {
      this.$router.push({ path: "/application/create" }).catch(() => {});
    },
    remove(application) {
      this.$store
        .dispatch("DELETE_APPLICATION", application.id)
        .then(() => {
          this.$toast.success("Лендинг удален");
          this.filterApps();
        })
        .catch(() => {
          this.$toast.error("Неизвестная ошибка!");
        });
    },
    onClear() {
      this.params.package_name = '';
      this.filterApps();
    },
    filterApps() {
      this.$store.dispatch("GET_APPLICATIONS", this.params);
    },
  },
};
</script>
